import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 639.000000 657.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,657.000000) scale(0.100000,-0.100000)">


<path d="M460 5726 c-48 -8 -66 -15 -68 -28 -3 -16 7 -18 95 -18 192 0 337
-60 512 -210 105 -90 274 -322 345 -473 22 -47 24 -23 -49 -637 -10 -90 -25
-216 -30 -265 -3 -27 -13 -108 -21 -180 -8 -71 -19 -166 -25 -210 -5 -44 -20
-181 -34 -305 -73 -657 -89 -768 -114 -819 -48 -93 -144 -152 -261 -161 -75
-5 -105 -24 -57 -35 44 -11 770 -1 774 11 2 7 -17 13 -51 17 -68 7 -107 27
-143 74 -34 44 -34 79 -8 358 9 99 19 207 21 240 6 80 47 545 73 820 11 121
30 333 41 470 11 138 23 266 26 285 l6 35 25 -55 c14 -30 39 -84 55 -120 54
-117 76 -163 127 -273 28 -60 51 -111 51 -113 0 -2 17 -41 39 -87 21 -45 51
-109 66 -142 15 -33 40 -87 55 -120 15 -33 44 -96 65 -140 36 -78 69 -150 105
-230 10 -22 44 -93 74 -158 31 -65 56 -120 56 -122 0 -2 18 -43 41 -91 51
-111 84 -183 119 -259 15 -33 43 -94 63 -135 19 -41 44 -95 55 -120 83 -183
91 -198 106 -196 22 3 24 7 29 46 2 19 7 42 10 50 3 8 15 47 28 85 12 39 30
88 40 110 10 22 27 63 37 90 11 28 32 77 47 110 15 33 47 105 72 160 25 55 51
114 58 130 7 17 28 62 45 100 18 39 40 90 50 115 9 25 29 72 45 105 45 99 70
155 120 270 26 61 60 137 75 170 15 33 42 94 60 135 17 41 42 98 55 125 12 28
39 88 60 135 20 47 57 129 81 182 24 54 44 99 44 102 0 2 13 33 29 68 15 35
31 72 35 82 14 40 25 -4 51 -204 14 -113 30 -232 35 -265 13 -82 40 -281 46
-330 2 -22 22 -168 44 -325 22 -157 51 -367 65 -468 18 -133 22 -189 15 -203
-7 -13 -7 -19 0 -19 5 0 10 -17 10 -38 0 -21 5 -43 10 -48 6 -6 15 -39 21 -75
20 -115 73 -272 135 -400 37 -76 108 -196 123 -209 3 -3 25 -30 48 -60 225
-295 584 -439 1006 -404 63 6 77 10 77 24 0 15 -16 18 -109 23 -449 26 -793
346 -947 882 -10 33 -18 65 -19 70 0 6 -7 37 -15 70 -7 33 -12 65 -10 70 5 17
-1 89 -8 95 -4 3 -9 34 -10 70 -2 36 -7 74 -12 85 -5 11 -16 82 -25 158 -19
157 -22 178 -35 227 -6 19 -10 49 -10 66 0 16 -4 34 -9 40 -5 5 -11 32 -14 59
-10 116 -41 330 -49 333 -4 2 -8 21 -8 42 0 21 -4 41 -8 44 -5 3 -12 56 -15
117 -4 61 -11 117 -16 123 -5 6 -12 43 -16 81 -4 39 -13 93 -21 120 -19 63
-49 239 -119 685 -2 14 -9 57 -14 95 -18 110 -21 122 -36 122 -8 0 -28 -33
-46 -76 -18 -42 -36 -80 -40 -86 -4 -5 -18 -37 -32 -70 -14 -33 -48 -109 -75
-170 -27 -60 -84 -189 -126 -285 -43 -96 -88 -197 -101 -225 -13 -27 -42 -93
-65 -145 -23 -52 -52 -117 -65 -145 -13 -27 -56 -124 -95 -215 -40 -91 -88
-196 -106 -235 -49 -107 -84 -185 -84 -190 0 -2 -18 -42 -40 -89 -22 -47 -40
-87 -40 -90 0 -2 -22 -52 -50 -111 -27 -59 -50 -108 -50 -111 0 -2 -13 -32
-29 -66 -15 -35 -39 -88 -52 -118 -45 -108 -41 -109 -109 33 -33 70 -60 129
-60 132 0 4 -45 101 -131 283 -27 57 -49 106 -49 108 0 3 -15 37 -34 77 -81
172 -126 269 -126 272 0 1 -34 73 -75 159 -41 86 -75 159 -75 162 0 3 -17 43
-39 87 -98 206 -131 280 -137 303 -3 13 -10 24 -15 24 -5 0 -9 4 -9 10 0 5
-12 35 -26 67 -126 280 -185 402 -230 480 -104 177 -245 335 -389 433 -27 18
-52 36 -55 40 -9 10 -134 67 -195 89 -134 47 -325 62 -465 37z"/>
<path d="M3030 5721 c0 -6 5 -13 12 -17 7 -4 8 -3 4 4 -5 9 255 12 1058 12
1005 0 1065 -1 1069 -17 4 -14 5 -12 6 5 l1 22 -1075 0 c-665 0 -1075 -4
-1075 -9z"/>
<path d="M3133 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3213 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3293 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3373 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3453 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3533 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3613 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3693 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3773 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3858 5703 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M3933 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4013 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4093 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4178 5703 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M4253 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4333 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4413 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4493 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4573 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4658 5703 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M4733 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4813 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4898 5703 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M4973 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5053 5703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5133 5699 c9 -5 17 -20 17 -31 0 -12 3 -19 6 -15 12 12 -6 57 -24
57 -16 -1 -16 -1 1 -11z"/>
<path d="M3608 5683 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3878 5683 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3933 5679 c9 -5 18 -22 18 -37 2 -24 2 -24 6 -4 2 12 9 22 14 22 5
0 9 7 9 16 0 14 -3 14 -15 4 -10 -9 -15 -9 -15 -1 0 6 -8 11 -17 11 -17 -1
-17 -1 0 -11z"/>
<path d="M4343 5683 c26 -2 68 -2 95 0 26 2 4 3 -48 3 -52 0 -74 -1 -47 -3z"/>
<path d="M4640 5680 c-34 -7 -34 -7 -5 -8 17 0 50 3 75 7 l45 8 -40 0 c-22 0
-56 -3 -75 -7z"/>
<path d="M4240 5668 c0 -4 5 -8 10 -8 6 0 10 -7 10 -15 0 -8 -6 -15 -12 -15
-10 0 -10 -2 -1 -8 10 -6 13 -264 15 -1213 l3 -1204 5 1237 c5 1173 4 1236
-12 1235 -10 -1 -18 -5 -18 -9z"/>
<path d="M4865 5670 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3360 5660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3430 5660 c-12 -8 -11 -10 5 -9 11 1 31 5 45 9 24 8 24 8 -5 9 -16
0 -37 -4 -45 -9z"/>
<path d="M4708 5663 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M3378 5643 c-10 -2 -18 -9 -18 -14 0 -6 10 -5 23 2 37 20 36 23 -5
12z"/>
<path d="M4830 5634 c56 -17 55 -17 40 1 -7 9 -28 15 -49 14 l-36 0 45 -15z"/>
<path d="M5172 5595 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3284 5595 c-10 -8 -14 -14 -9 -14 6 0 19 6 29 14 11 8 15 15 10 15
-6 0 -19 -7 -30 -15z"/>
<path d="M4951 5591 c-12 -8 -11 -13 10 -30 27 -23 39 -26 39 -10 0 6 -9 13
-19 16 -12 3 -17 11 -14 19 6 16 3 17 -16 5z"/>
<path d="M3220 5558 c-11 -12 -20 -25 -20 -30 0 -4 -8 -5 -17 -1 -10 3 -15 3
-11 -2 4 -4 -4 -21 -17 -37 -14 -16 -25 -34 -25 -40 0 -6 1 -9 3 -7 1 3 31 35
67 72 35 37 59 67 53 67 -7 0 -21 -10 -33 -22z"/>
<path d="M5020 5564 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M3018 5466 c-2 -53 0 -96 3 -96 4 0 9 -19 13 -42 l7 -42 14 28 c18
33 18 36 6 36 -5 0 -12 -8 -15 -17 -3 -10 -4 -6 -2 10 2 15 0 27 -4 27 -4 0
-8 41 -9 91 0 51 -3 94 -5 96 -3 3 -6 -38 -8 -91z"/>
<path d="M5013 5532 c-7 -5 1 -19 23 -42 19 -19 34 -28 34 -22 0 7 -7 15 -16
19 -8 3 -13 11 -10 19 6 16 -16 35 -31 26z"/>
<path d="M3140 5520 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5111 5494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5082 5472 c3 -12 2 -22 -2 -24 -5 -2 3 -16 17 -33 23 -26 24 -27 10
-4 -9 15 -13 36 -11 47 3 11 0 23 -7 27 -9 6 -11 2 -7 -13z"/>
<path d="M3046 5432 c-2 -4 -1 -14 5 -22 7 -12 9 -10 9 8 0 23 -5 28 -14 14z"/>
<path d="M3096 5422 c-10 -17 -26 -62 -21 -62 7 0 37 62 32 67 -3 2 -8 0 -11
-5z"/>
<path d="M5175 5340 c20 -39 19 -54 -2 -45 -14 5 -15 3 -6 -6 13 -13 33 -4 33
16 0 21 -22 65 -32 65 -5 0 -2 -13 7 -30z"/>
<path d="M3010 5321 c0 -17 4 -32 8 -35 4 -2 6 11 4 30 -5 42 -12 45 -12 5z"/>
<path d="M5140 5343 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M3973 4690 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3944 4567 c-3 -16 -4 -31 0 -34 3 -4 6 5 6 18 0 19 2 21 10 9 5 -8
10 -10 10 -5 0 6 -4 17 -9 24 -8 12 -11 9 -17 -12z"/>
<path d="M3983 4530 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3956 4505 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M4273 3145 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M4252 3100 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4275 3060 c-4 -12 -5 -24 -2 -27 3 -2 8 5 12 17 4 12 5 24 2 27 -3
2 -8 -5 -12 -17z"/>
<path d="M4246 3053 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4290 3000 c0 -16 4 -30 8 -30 5 0 7 14 4 30 -2 17 -6 30 -8 30 -2 0
-4 -13 -4 -30z"/>
<path d="M4261 3004 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4324 2927 c3 -10 6 -22 6 -27 0 -4 9 -10 20 -13 11 -3 20 -12 20
-21 0 -9 5 -16 10 -16 17 0 11 18 -13 37 -13 10 -29 27 -36 38 -11 17 -12 17
-7 2z"/>
<path d="M3810 2860 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z"/>
<path d="M4407 2839 c3 -11 10 -19 17 -20 6 0 2 -5 -9 -10 l-20 -9 20 3 c27 3
33 43 7 49 -15 4 -18 1 -15 -13z"/>
<path d="M3782 2830 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3800 2819 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1338 2233 c2 -12 6 -23 7 -23 1 0 5 11 7 23 3 14 0 24 -7 24 -7 0
-10 -10 -7 -24z"/>
<path d="M1442 1983 c-1 -155 -5 -290 -8 -300 -5 -16 -4 -16 5 -1 8 11 10 108
8 300 l-2 283 -3 -282z"/>
<path d="M1714 2247 c-2 -7 -3 -118 -2 -247 l3 -235 5 248 c3 136 4 247 2 247
-1 0 -5 -6 -8 -13z"/>
<path d="M1770 1962 l5 -297 3 289 c1 159 -1 293 -5 297 -5 5 -6 -126 -3 -289z"/>
<path d="M1814 1960 c0 -168 2 -236 3 -152 2 83 2 221 0 305 -1 83 -3 15 -3
-153z"/>
<path d="M1464 1950 c0 -168 2 -236 3 -152 2 83 2 221 0 305 -1 83 -3 15 -3
-153z"/>
<path d="M1794 1970 c0 -157 2 -221 3 -142 2 78 2 206 0 285 -1 78 -3 14 -3
-143z"/>
<path d="M3233 2210 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3253 2210 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M1306 2209 c-8 -42 0 -50 9 -9 4 17 5 34 2 36 -2 3 -7 -10 -11 -27z"/>
<path d="M2355 2230 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
0 -12 -4 -9 -10z"/>
<path d="M2320 2220 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2400 2221 c0 -6 -10 -12 -22 -12 -21 -1 -21 -2 -3 -6 11 -3 24 -9
30 -14 5 -5 16 -9 25 -9 12 1 12 2 -2 11 -10 5 -18 15 -18 20 0 6 20 -1 45
-14 51 -29 59 -28 18 1 -36 25 -73 37 -73 23z"/>
<path d="M1041 2195 c0 -11 4 -27 9 -35 9 -13 10 -13 9 0 0 8 -4 24 -9 35 l-9
20 0 -20z"/>
<path d="M2309 2169 c-10 -6 -4 -9 19 -9 17 0 32 2 32 4 0 9 -39 13 -51 5z"/>
<path d="M2385 2170 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2445 2170 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M980 2150 c6 -11 12 -20 14 -20 2 0 2 9 -1 20 -3 11 -9 20 -15 20 -5
0 -4 -9 2 -20z"/>
<path d="M2477 2153 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M2510 2155 c0 -18 19 -40 26 -32 4 4 0 15 -9 24 -9 10 -17 13 -17 8z"/>
<path d="M962 2130 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1290 2140 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M2902 2120 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M1011 2133 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
<path d="M1031 2124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1061 2130 c0 -8 4 -22 9 -30 12 -18 12 -2 0 25 -6 13 -9 15 -9 5z"/>
<path d="M2426 2119 c12 -13 20 -16 22 -9 3 7 -2 16 -9 21 -25 16 -32 9 -13
-12z"/>
<path d="M1245 2007 c-16 -56 -33 -108 -38 -115 -6 -7 -8 -15 -5 -17 8 -8 25
34 49 118 15 52 25 74 31 65 6 -9 7 -4 5 14 -6 50 -13 38 -42 -65z"/>
<path d="M1376 2033 c-8 -98 -7 -333 2 -355 4 -9 7 84 8 208 0 123 -1 224 -2
224 0 0 -4 -34 -8 -77z"/>
<path d="M1315 2089 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M1014 2075 c-3 -8 -4 -20 -2 -27 3 -7 7 -1 10 15 6 29 1 36 -8 12z"/>
<path d="M1403 2050 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M2447 2078 c4 -7 13 -29 19 -48 l10 -35 0 47 c-1 25 -4 41 -8 35 -6
-9 -8 -9 -8 1 0 6 -5 12 -11 12 -6 0 -7 -5 -2 -12z"/>
<path d="M1085 2045 c7 -31 12 -41 19 -34 2 2 -3 18 -11 34 l-14 30 6 -30z"/>
<path d="M1327 2063 c-4 -3 -7 -15 -7 -25 0 -10 -4 -18 -10 -18 -5 0 -9 -3 -8
-7 3 -14 -13 -88 -23 -105 -12 -23 -12 -41 0 -33 5 3 12 20 16 38 4 17 16 59
26 91 18 57 20 74 6 59z"/>
<path d="M2561 2060 c0 -8 4 -24 9 -35 5 -13 9 -14 9 -5 0 8 -4 24 -9 35 -5
13 -9 14 -9 5z"/>
<path d="M2902 2040 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M1051 2034 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1021 2025 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M3223 1965 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M1261 1974 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1264 1932 c-10 -7 -27 -42 -20 -42 2 0 11 11 20 25 17 26 17 29 0
17z"/>
<path d="M1083 1900 c6 -16 13 -28 15 -26 6 6 -10 56 -18 56 -5 0 -3 -13 3
-30z"/>
<path d="M2567 1906 c-5 -15 -6 -31 -3 -34 8 -8 18 25 14 45 -3 13 -6 10 -11
-11z"/>
<path d="M2895 1920 c-19 -8 -19 -9 3 -9 12 -1 22 4 22 9 0 6 -1 10 -2 9 -2 0
-12 -4 -23 -9z"/>
<path d="M2938 1923 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1060 1903 c0 -7 8 -36 19 -65 10 -29 23 -69 29 -88 10 -35 10 -35 5
8 -6 41 -52 170 -53 145z"/>
<path d="M1134 1878 c0 -21 5 -33 10 -28 4 4 4 21 -2 37 -10 28 -10 28 -8 -9z"/>
<path d="M2471 1894 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2890 1890 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2880 1860 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M1215 1849 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M2450 1846 c0 -8 -11 -28 -25 -44 -23 -28 -24 -30 -5 -25 11 3 23 16
26 29 3 13 10 24 15 24 5 0 9 7 9 15 0 8 -4 15 -10 15 -5 0 -10 -6 -10 -14z"/>
<path d="M1256 1835 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M2537 1826 c-5 -15 -6 -31 -3 -34 8 -8 18 25 14 45 -3 13 -6 10 -11
-11z"/>
<path d="M1191 1828 c-1 -9 -4 -25 -7 -35 -5 -14 -4 -15 4 -4 8 11 11 8 14
-15 2 -16 6 -24 9 -19 4 6 1 28 -6 50 -7 22 -13 32 -14 23z"/>
<path d="M2903 1795 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M1162 1790 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1141 1784 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1232 1761 c-17 -48 -14 -63 4 -22 8 19 13 39 11 45 -2 6 -9 -5 -15
-23z"/>
<path d="M2470 1770 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2498 1752 c-13 -15 -31 -31 -41 -35 -9 -3 -17 -11 -17 -17 0 -16 48
19 71 51 26 37 18 37 -13 1z"/>
<path d="M2360 1754 c-24 -13 -24 -14 -4 -14 12 0 24 7 28 15 3 8 4 15 3 14
-1 -1 -13 -7 -27 -15z"/>
<path d="M2396 1743 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2881 1744 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2160 1740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2255 1730 c69 -6 69 -6 16 -8 -46 -2 -52 -4 -41 -17 7 -8 24 -14 39
-14 25 1 25 2 -4 9 -25 6 -22 8 24 9 46 1 52 3 41 16 -9 11 -30 15 -79 13
l-66 -2 70 -6z"/>
<path d="M3012 1733 c48 -2 129 -2 180 0 51 1 12 3 -87 3 -99 0 -141 -2 -93
-3z"/>
<path d="M2173 1713 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2350 1710 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M2953 1713 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M3038 1713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3213 1713 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M1135 1680 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1190 1681 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M940 1670 l-25 -8 28 -1 c15 -1 27 4 27 9 0 6 -1 10 -2 9 -2 -1 -14
-5 -28 -9z"/>
<path d="M1400 1669 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1718 1668 c6 -6 18 -8 28 -6 14 3 12 5 -9 10 -19 3 -25 2 -19 -4z"/>
<path d="M2340 1670 c-20 -6 -21 -8 -5 -8 11 0 31 4 45 8 20 6 21 8 5 8 -11 0
-31 -4 -45 -8z"/>
<path d="M2823 1663 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M3044 1663 c9 -10 146 -11 152 -1 3 4 -32 8 -77 8 -45 0 -79 -3 -75
-7z"/>
<path d="M3010 1290 c0 -17 -8 -20 -87 -23 l-88 -3 93 -2 92 -2 0 25 c0 14 -2
25 -5 25 -3 0 -5 -9 -5 -20z"/>
<path d="M3834 1296 c-3 -8 -4 -23 -2 -33 3 -11 6 -6 9 15 6 34 2 42 -7 18z"/>
<path d="M4392 1303 c18 -3 25 -9 21 -19 -3 -8 3 -16 13 -20 11 -3 26 -9 35
-14 17 -9 29 -4 29 12 0 4 -3 7 -7 7 -5 -1 -18 -1 -30 0 -27 1 -31 21 -5 22
26 2 -28 17 -58 17 -24 -1 -24 -1 2 -5z"/>
<path d="M5384 1222 c-22 -66 -28 -94 -21 -110 5 -11 6 -29 2 -39 -9 -21 -15
-63 -10 -63 4 0 35 104 35 117 0 4 -5 1 -10 -7 -17 -27 -12 35 6 66 l16 29 -6
-33 c-3 -18 -2 -31 3 -28 5 3 11 19 13 35 3 18 0 28 -5 24 -6 -4 -8 3 -4 18 4
13 7 32 7 42 0 10 10 22 23 27 21 8 21 9 1 9 -17 1 -25 -14 -50 -87z"/>
<path d="M1190 1263 c0 -35 3 -41 16 -36 12 4 13 8 3 13 -6 5 -9 14 -6 22 3 8
1 21 -4 29 -6 9 -9 0 -9 -28z"/>
<path d="M1590 1128 c0 -94 3 -169 6 -166 8 9 10 338 2 338 -5 0 -8 -77 -8
-172z"/>
<path d="M1644 1175 c0 -71 1 -99 3 -62 2 37 2 96 0 130 -2 34 -3 4 -3 -68z"/>
<path d="M2350 1289 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2760 1128 c0 -94 2 -169 5 -166 9 8 11 338 3 338 -5 0 -8 -77 -8
-172z"/>
<path d="M3308 1293 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3368 1293 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3638 1253 l-1 -48 -7 45 -7 45 -1 -40 c-2 -49 14 -82 22 -44 10 50
12 89 4 89 -4 0 -9 -21 -10 -47z"/>
<path d="M3875 1130 c0 -93 3 -170 8 -170 4 0 7 77 7 170 0 94 -3 170 -7 170
-5 0 -8 -76 -8 -170z"/>
<path d="M4761 1293 c-22 -9 -71 -55 -71 -67 0 -5 15 4 33 21 17 18 43 36 57
42 14 5 18 10 10 10 -8 -1 -21 -3 -29 -6z"/>
<path d="M4880 1290 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M5151 1263 l2 -38 7 35 c4 20 7 -36 7 -130 -1 -132 -2 -148 -7 -80
l-6 85 -2 -88 c-2 -67 1 -88 11 -84 9 3 12 45 12 167 0 122 -3 164 -12 168 -9
2 -13 -8 -12 -35z"/>
<path d="M5219 1291 c9 -6 12 -19 8 -39 -7 -37 2 -102 15 -102 5 0 7 8 4 18
-16 55 -18 75 -7 68 9 -6 10 0 5 21 -9 37 -13 43 -27 43 -8 0 -7 -3 2 -9z"/>
<path d="M941 1254 c0 -21 4 -48 9 -59 5 -12 6 1 3 35 -7 66 -13 79 -12 24z"/>
<path d="M1440 1280 c0 -5 -39 -11 -87 -13 l-88 -3 93 -2 c76 -2 92 1 92 13 0
8 -2 15 -5 15 -3 0 -5 -4 -5 -10z"/>
<path d="M2032 1270 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2050 1278 c0 -16 10 -18 98 -16 l97 2 -90 3 c-55 3 -92 8 -97 16 -5
8 -8 6 -8 -5z"/>
<path d="M2430 1281 c0 -6 -19 -12 -42 -14 l-43 -4 46 -1 c34 -2 48 2 53 13 3
8 1 15 -4 15 -6 0 -10 -4 -10 -9z"/>
<path d="M2462 1270 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2608 1283 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3198 1260 c-28 -30 -37 -60 -19 -60 6 0 13 5 17 12 4 7 3 8 -4 4
-25 -15 -11 13 18 37 17 14 30 28 30 31 0 13 -17 4 -42 -24z"/>
<path d="M3426 1258 c16 -18 30 -35 32 -37 1 -2 2 1 2 7 0 13 -44 62 -56 62
-4 0 5 -15 22 -32z"/>
<path d="M4944 1261 c16 -16 26 -33 23 -38 -2 -4 -1 -17 4 -28 l8 -20 1 20 1
20 9 -20 c5 -11 8 -40 8 -65 -1 -45 -1 -45 -8 -10 -6 27 -7 23 -4 -20 2 -41 0
-50 -7 -35 -8 18 -9 18 -5 -4 4 -17 -3 -32 -25 -52 -35 -33 -38 -45 -4 -23 55
36 81 146 51 217 -14 35 -57 87 -72 87 -4 0 5 -13 20 -29z"/>
<path d="M5431 1274 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M830 1269 l-55 -7 58 2 c31 0 59 -3 61 -9 3 -5 5 -2 5 8 1 9 -2 16
-6 15 -5 -1 -33 -5 -63 -9z"/>
<path d="M1010 1270 l-55 -7 58 -1 c32 -1 57 2 57 8 0 6 -1 9 -2 8 -2 -1 -28
-4 -58 -8z"/>
<path d="M3280 1270 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4288 1269 c-19 -11 -43 -49 -31 -48 4 0 18 13 31 30 26 32 26 33 0
18z"/>
<path d="M4333 1263 c-13 -9 -23 -20 -23 -26 0 -5 8 -3 18 5 9 9 24 17 32 20
7 2 10 7 5 10 -6 3 -20 -1 -32 -9z"/>
<path d="M5201 1264 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1230 1260 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M2563 1263 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M3230 1245 c-13 -14 -19 -25 -13 -25 5 0 20 11 33 25 13 14 19 25 13
25 -5 0 -20 -11 -33 -25z"/>
<path d="M3366 1256 c18 -8 35 -19 38 -25 4 -6 12 -11 18 -11 6 0 1 11 -12 25
-14 14 -34 25 -49 25 -25 -1 -25 -1 5 -14z"/>
<path d="M4500 1262 c0 -6 6 -15 14 -19 7 -4 16 -15 19 -23 5 -13 6 -13 6 1 1
9 -8 24 -19 34 -11 10 -20 13 -20 7z"/>
<path d="M4751 1248 c-19 -12 -29 -24 -22 -26 7 -2 22 5 34 16 12 11 31 22 42
25 l20 5 -20 1 c-11 0 -35 -9 -54 -21z"/>
<path d="M4890 1264 c0 -3 10 -14 23 -25 22 -19 47 -25 47 -11 -1 8 -70 44
-70 36z"/>
<path d="M1232 1220 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2510 1221 c0 -25 6 -27 13 -6 4 8 2 17 -3 20 -6 4 -10 -3 -10 -14z"/>
<path d="M2800 1230 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M902 1093 c-2 -130 -1 -141 16 -137 15 4 16 15 12 92 l-5 87 -5 -85
c-4 -83 -5 -82 -10 50 l-6 135 -2 -142z"/>
<path d="M4273 1213 c-7 -2 -13 -17 -12 -31 l1 -27 10 29 c8 21 13 25 19 15 7
-11 9 -10 9 4 0 17 -5 19 -27 10z"/>
<path d="M5251 1210 c0 -8 4 -24 9 -35 5 -13 9 -14 9 -5 0 8 -4 24 -9 35 -5
13 -9 14 -9 5z"/>
<path d="M2021 1194 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4676 1195 c-3 -9 -5 -40 -5 -68 l2 -52 7 65 c8 67 7 82 -4 55z"/>
<path d="M4701 1194 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4720 1180 c0 -16 2 -30 4 -30 2 0 6 14 8 30 3 17 1 30 -4 30 -4 0
-8 -13 -8 -30z"/>
<path d="M1190 1130 c0 -53 3 -67 15 -67 11 0 12 4 5 11 -14 14 -13 73 3 79
10 4 10 6 0 6 -18 1 -16 21 2 21 8 0 15 -6 15 -14 0 -21 24 -26 123 -24 l92 2
-100 5 c-85 5 -100 9 -99 23 1 11 -8 18 -27 20 l-29 5 0 -67z"/>
<path d="M3604 1187 c3 -10 6 -23 6 -28 0 -5 5 -9 10 -9 14 0 12 13 -6 36 -14
17 -15 17 -10 1z"/>
<path d="M3651 1188 c-2 -41 9 -48 80 -48 42 0 69 -4 69 -11 0 -6 -24 -10 -63
-8 -76 3 -87 -3 -88 -44 -1 -21 -3 -25 -6 -12 -3 11 -13 27 -22 36 -16 15 -17
14 -17 -17 0 -28 2 -30 12 -16 11 14 12 8 10 -38 -3 -48 -3 -50 4 -15 l7 40 1
-47 c1 -27 6 -48 10 -48 4 0 6 15 4 33 -2 17 1 50 5 72 l9 40 69 5 c55 4 70 9
73 22 3 15 -7 18 -69 20 -68 3 -74 5 -80 28 -4 14 -7 18 -8 8z"/>
<path d="M3158 1174 c-5 -4 -8 -19 -8 -33 0 -26 19 -106 24 -99 6 9 -10 139
-16 132z"/>
<path d="M4230 1132 c0 -29 4 -52 8 -52 9 0 7 89 -3 98 -3 3 -5 -18 -5 -46z"/>
<path d="M4953 1128 c0 -52 0 -53 8 -15 5 21 5 45 1 54 -6 9 -9 -5 -9 -39z"/>
<path d="M5152 1160 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2013 1141 c-2 -23 -1 -24 7 -6 8 19 9 19 9 -2 1 -13 -3 -23 -9 -23
-5 0 -10 -4 -10 -10 0 -5 7 -7 15 -4 18 7 20 60 2 67 -8 3 -13 -5 -14 -22z"/>
<path d="M2805 1130 c0 -21 3 -36 7 -34 5 3 8 18 8 34 0 16 -3 31 -8 34 -4 2
-7 -13 -7 -34z"/>
<path d="M4283 1130 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M4692 1140 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M2070 1148 c0 -5 28 -7 63 -7 67 1 60 6 -15 11 -27 2 -48 0 -48 -4z"/>
<path d="M2051 1133 c-1 -22 2 -23 72 -21 l72 2 -67 3 c-53 3 -68 7 -71 21 -3
12 -5 11 -6 -5z"/>
<path d="M2212 1130 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2868 1143 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"/>
<path d="M2970 1139 c0 -5 5 -7 10 -4 6 3 10 2 10 -4 0 -6 -34 -12 -77 -14
l-78 -3 85 -2 c80 -2 86 -1 86 18 0 13 -7 20 -18 20 -10 0 -18 -5 -18 -11z"/>
<path d="M5256 1134 c-3 -12 -2 -32 4 -45 9 -21 9 -20 4 10 -4 22 -3 31 4 27
6 -3 14 -1 18 5 4 7 3 9 -4 5 -5 -3 -12 0 -15 6 -2 8 -7 4 -11 -8z"/>
<path d="M1210 1129 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3630 1129 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2494 1046 c1 -54 5 -81 14 -84 9 -3 12 18 10 85 l-2 88 -6 -85 -6
-85 -5 80 -5 80 0 -79z"/>
<path d="M3195 1117 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17 -12
0z"/>
<path d="M4711 1115 c0 -11 6 -31 14 -45 l15 -25 -6 30 c-4 17 -10 37 -15 45
-7 12 -8 11 -8 -5z"/>
<path d="M1234 1105 c-3 -8 -4 -20 -1 -27 3 -7 6 -3 6 9 1 21 6 22 104 26
l102 3 -103 2 c-82 2 -104 -1 -108 -13z"/>
<path d="M5341 1093 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
<path d="M2006 1072 c7 -4 14 -18 17 -32 4 -21 4 -20 6 8 1 24 -3 32 -16 32
-12 0 -14 -3 -7 -8z"/>
<path d="M3216 1055 c7 -35 21 -55 39 -55 8 0 15 -5 17 -12 3 -8 9 -8 24 0 19
11 19 11 -1 12 -26 1 -61 29 -75 60 -10 24 -10 24 -4 -5z"/>
<path d="M4701 1068 c-1 -9 4 -20 9 -23 6 -3 10 -11 10 -17 0 -7 -6 -6 -15 2
-33 27 -13 -5 23 -37 39 -34 72 -43 72 -19 0 8 6 17 13 19 7 3 5 6 -5 6 -10 1
-18 -6 -18 -14 0 -21 -13 -19 -45 9 -15 13 -23 26 -17 30 5 3 15 -1 22 -9 7
-8 17 -15 23 -15 5 0 2 9 -8 20 -10 11 -24 20 -32 20 -7 0 -18 10 -23 23 -6
16 -9 17 -9 5z"/>
<path d="M4291 1063 c-1 -7 -8 -10 -18 -6 -10 4 -4 -7 14 -25 18 -17 44 -34
58 -38 29 -7 33 -21 8 -27 -11 -2 -3 -7 19 -11 22 -5 48 -4 65 3 25 10 24 10
-15 5 -36 -5 -41 -3 -36 10 4 10 -1 18 -12 22 -44 14 -64 29 -73 53 -5 14 -10
21 -10 14z"/>
<path d="M5297 1056 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 -3 17 -4 17 -11
-1z"/>
<path d="M4496 1044 c-9 -24 -7 -27 9 -10 17 17 19 26 6 26 -5 0 -12 -7 -15
-16z"/>
<path d="M5270 1045 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M1231 1034 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3435 1013 c-42 -46 -43 -48 -17 -31 24 16 57 57 51 62 -2 1 -17 -13
-34 -31z"/>
<path d="M1190 997 c0 -28 3 -37 9 -28 5 8 7 21 4 29 -3 8 0 17 6 22 10 5 9 9
-3 13 -13 5 -16 -1 -16 -36z"/>
<path d="M3390 1015 c-13 -14 -19 -25 -13 -25 5 0 20 11 33 25 13 14 19 25 13
25 -5 0 -20 -11 -33 -25z"/>
<path d="M3830 1002 c0 -22 4 -43 9 -46 5 -4 7 12 4 36 -8 57 -13 61 -13 10z"/>
<path d="M4506 1009 c-16 -16 -25 -29 -19 -29 11 0 58 48 52 54 -2 2 -17 -9
-33 -25z"/>
<path d="M4913 1021 c-45 -38 -17 -35 32 3 15 11 16 15 5 15 -8 0 -25 -8 -37
-18z"/>
<path d="M3186 1016 c7 -18 54 -50 54 -37 0 6 -9 15 -20 21 -11 6 -18 15 -15
21 4 5 -1 9 -9 9 -9 0 -13 -6 -10 -14z"/>
<path d="M4260 1023 c0 -13 29 -38 35 -31 3 3 -4 13 -15 23 -11 10 -20 13 -20
8z"/>
<path d="M4040 1005 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M4075 995 c4 -38 15 -43 15 -7 0 18 -4 32 -9 32 -5 0 -8 -11 -6 -25z"/>
<path d="M4450 1003 c-14 -7 -18 -13 -10 -13 17 0 47 17 40 23 -3 2 -16 -2
-30 -10z"/>
<path d="M1230 1000 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M1652 990 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2032 990 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2800 1000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5293 988 c15 -34 48 -38 46 -5 -1 16 -2 18 -6 5 -6 -23 -11 -23 -34
5 l-19 22 13 -27z"/>
<path d="M1313 993 c26 -2 68 -2 95 0 26 2 4 3 -48 3 -52 0 -74 -1 -47 -3z"/>
<path d="M1670 983 c0 -12 3 -14 8 -6 5 7 40 13 87 16 l80 3 -87 2 c-78 2 -88
0 -88 -15z"/>
<path d="M2051 983 c0 -11 3 -13 6 -5 3 8 32 12 93 12 76 0 89 -2 93 -17 4
-14 5 -12 6 5 1 21 -2 22 -99 22 -89 0 -100 -2 -99 -17z"/>
<path d="M2888 993 c28 -2 76 -2 105 0 28 2 5 3 -53 3 -58 0 -81 -1 -52 -3z"/>
<path d="M1976 975 c-3 -9 0 -15 9 -15 9 0 12 6 9 15 -4 8 -7 15 -9 15 -2 0
-5 -7 -9 -15z"/>
<path d="M2005 980 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M1810 976 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M3370 970 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
